<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="4">
        <BoxUsuariosEmpresarios />
      </v-col>
      <v-col cols="12" md="4">
        <BoxVendasTipos />
      </v-col>
      <v-col cols="12" md="4">
        <BoxUsuariosCheckin />
      </v-col>
      <v-col cols="12" md="4">
        <BoxUsuariosRoles />
      </v-col>
      <v-col cols="12" md="4">
        <BoxUsuariosGeneros />
      </v-col>
      <v-col cols="12" md="4">
        <BoxTopDispositivos />
      </v-col>
    </v-row>

    <v-row>
      <v-col col="12" md="6">
        <v-row>
          <v-col cols="12" md="12">
            <BoxUsuariosCadastrados />
          </v-col>
          <v-col cols="12" md="12">
            <BoxSalesEduzzPorDia />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <MapaBrasil />
      </v-col>
    </v-row>

    <v-row>
      <v-col col="12" md="6">
        <BoxUsuariosCheckinPorDia />
      </v-col>
      <v-col col="12" md="6">
        <BoxGruposRenda />
      </v-col>
      <v-col col="12" md="6">
        <BoxShow />
      </v-col>
      <v-col col="12" md="6">
        <BoxNoShow />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="4">
        <BoxTopNetworkings />
      </v-col>

      <v-col cols="12" md="4">
        <BoxTopPostsCurtidas />
      </v-col>

      <v-col cols="12" md="4">
        <BoxMaioresDesejos />
      </v-col>

      <v-col cols="12" md="4">
        <BoxMaioresDores />
      </v-col>

      <v-col cols="12" md="4">
        <BoxPorqueParticipou />
      </v-col>
    </v-row>

    <BoxTagGroups />
  </v-container>
</template>

<script>
export default {
  name: "Home",
  components: {
    BoxUsuariosRoles: () => import("./components/BoxUsuariosRoles.vue"),
    BoxUsuariosCadastrados: () =>
      import("./components/BoxUsuariosCadastrados.vue"),
    BoxUsuariosCheckin: () => import("./components/BoxUsuariosCheckin.vue"),
    BoxUsuariosEmpresarios: () =>
      import("./components/BoxUsuariosEmpresarios.vue"),
    BoxSalesEduzzPorDia: () => import("./components/BoxSalesEduzzPorDia.vue"),
    BoxVendasTipos: () => import("./components/BoxVendasTipos.vue"),
    MapaBrasil: () => import("./components/mapas/Brasil/MapaBrasil.vue"),
    BoxTopNetworkings: () => import("./components/BoxTopNetworkings.vue"),
    BoxTopPostsCurtidas: () => import("./components/BoxTopPostsCurtidas.vue"),
    BoxTopDispositivos: () => import("./components/BoxTopDispositivos.vue"),
    BoxMaioresDesejos: () => import("./components/BoxMaioresDesejos.vue"),
    BoxMaioresDores: () => import("./components/BoxMaioresDores.vue"),
    BoxPorqueParticipou: () => import("./components/BoxPorqueParticipou.vue"),
    BoxTagGroups: () => import("./components/BoxTagGroups.vue"),
    BoxUsuariosCheckinPorDia: () =>
      import("./components/BoxUsuariosCheckinPorDia.vue"),
    BoxUsuariosGeneros: () => import("./components/BoxUsuariosPorGenero.vue"),
    BoxGruposRenda: () => import("./components/BoxGruposRenda.vue"),
    BoxShow: () => import("./components/BoxShow.vue"),
    BoxNoShow: () => import("./components/BoxNoShow.vue"),
  },
};
</script>

<style></style>
